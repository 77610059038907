@tailwind base;
@tailwind components;
@tailwind utilities;


.x-space-force {
    width:auto;
    height:0px;
    line-height:1px;
}

.prose {
  h2, h3 {
    @apply mt-0;
  }

  .responsive-object {
    @apply relative;
    iframe,
    video {
      @apply absolute w-full h-full;
    }
  }
  .richtext-image {
    &.full-width {
      @apply block mx-auto w-full h-auto;
    }
    &.left {
      @apply float-left mr-12 mb-0;
    }
    &.right {
      @apply ml-12;
      /* @apply float-right ml-12;*/
    }
  }
  a {
    color: #1932B8;
    &:visited {
      color: #5d69af;
    }
  }
  ul, ol {
    overflow: auto;
  }
}

.post_content {
  .img-contain-75 {
    @apply mx-auto w-full md:w-9/12;
    /* text-align: center; */

    img {
      @apply mx-auto;
    }
  }
  
  .img-contain-default {
    @apply mx-auto w-full px-0 md:px-6;
  }

  .block-content , .block-content_cleared {
    @apply mb-12;
    @apply mt-12;
  }

  .block-content:first-child {
    @apply mt-4;
  }
}

.media-content-after-title {
  .embed-container {
    position: relative;
    overflow: hidden;
    
    max-width: 100%; 

    &.content-after-title-img {
      position: relative;
      padding-bottom: 0px;
      height: auto;
      overflow: inherit;
      
      max-width: 100%;
    }
    &.content-after-title {
      position: relative;
      padding-bottom: 0px;
      height: auto;
      overflow: inherit;

      .img-contain-default {
        @apply mx-auto w-full px-0;
      }
    }
  }

  figcaption, .post_content figcaption {
    text-align:left;
  }
}
.embed-container iframe, .embed-container object, .embed-container embed { 
  position: absolute; top: 0; left: 0; width: 100%; height: 100%; 
}

.container-padding {
  @apply px-6 md:px-10 lg:px-4;
}


/* NAV */
.has-submenu.closed ul.submenu-ul {
  @apply lg:hidden;
}

.has-submenu.open ul.submenu-ul {
  @apply lg:inline-block;
  @apply z-50;
}

.has-submenu.open a.submenu-label span {
  @apply lg:bg-midGrey;
  @apply lg:text-black;
}


header nav.closed {
  #menu-button .open-elem {
    @apply hidden;
    
  }
  #menu-button .closed-elem {
    @apply inline-block;
  }

  #nav-menu-sm, #nav-menu-search-sm {
    @apply hidden;
  }
}

#large-menu-search {
  #large-search-form {
    @apply invisible;
  }
}

#large-menu-search.open {
  #large-search-form {
    @apply visible;
    @apply z-10;
  }
}

#nav-menu .open {
  .open-elem {
    display: inline-block;
  }
  .closed-elem {
    display: none;
  }
}

#nav-menu .closed {
  .open-elem {
    display: none;
  }
  .closed-elem {
    display: inline-block;
  }
}

nav.open {
  .open-elem {
    display: inline-block;
  }
  .closed-elem {
    display: none;
  }
}

nav.closed {
  .open-elem {
    display: none;
  }
  .closed-elem {
    display: inline-block;
  }
}

.classrooom-caption {
  @apply mx-auto w-full h-auto block;
  .richtext-image {
    margin-bottom:1rem;
  }
}

/* artificial vertical space*/
div.v2-br {
  overflow-y: hidden;
  height: 1rem;
}

.richtext-image {
  margin-bottom:1em;
}

.richtext-image-caption {
  padding-top:0px;
  margin-top:-.75em;
}

.featured-pages-carousel {

  .slick-prev, .slick-next {
    @apply top-[107%];
  }

  .slick-prev::before, .slick-next::before {
    @apply sm:text-[37px];
  }
  
  .slick-next {
    @apply right-[20px];
    @apply sm:right-[100px];
    z-index:1000;
  }
  .slick-prev {
    @apply left-[20px];
    @apply sm:left-[100px];
    z-index:1000;
  }

  img{
    border-bottom: 1px solid rgba(42, 26, 165, 0.339);
  }

  .slick-slider p {
    border-bottom: 1px solid rgba(83, 83, 83, 0.239);
  }
}

#home-optional-body {

  a {
    /* @apply text-redLink; */
    @apply underline;
    @apply text-blueDark;
    font-weight: 700;
  }

  .block-heading {
    @apply text-xl;
    @apply leading-5;
    @apply text-blueDark;
    @apply pb-3;

    /* md */
    @apply md:text-2xl;


  }
} 

